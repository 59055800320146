import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {DiTime} from 'app/blocks/util/di-time';
import {INote, Note} from 'app/blocks/model/note.model';
import {BehaviorSubject} from 'rxjs';
import {Address, IAddress} from 'app/blocks/model/address.model';
import {MposDate} from 'app/blocks/util/mpos-date';

export class DeliveryWindow {
    id: number;
    startTime: any;
    endTime: any;
    priority: number;

    constructor(window?: any) {
        if (window) {
            this.id = window.id;
            this.startTime = DiTime.newInstance(window.startTime);
            this.endTime = DiTime.newInstance(window.endTime);
            this.priority = window.priority;
        }
    }
}

export class RouteLite {
    id: number;
    name: string;
    color: string;

    constructor(route?: any) {
        if (route) {
            this.id = route.id;
            this.name = route.name;
            this.color = route.color;
        }
    }
}

export class EmployeeLite {
    id: number;
    employeeId: string;
    name: string;

    constructor(employee?: any) {
        if (employee) {
            this.id = employee.id;
            this.name = employee.name;
            this.employeeId = employee.employeeId;
        }
    }
}

export class DeliveryPointLite {
    id: number;
    accountName: string;
    line1: string;
    townCity?: string;
    postCode?: string;

    constructor(deliveryPoint?: any) {
        if (deliveryPoint) {
            this.id = deliveryPoint.id;
            this.accountName = deliveryPoint.accountName;
            this.line1 = deliveryPoint.line1;
            this.townCity = deliveryPoint.townCity;
            this.postCode = deliveryPoint.postCode;
        }
    }
}

export class CustomerLite {
    id: number;
    name: string;
    accountNo: string;

    constructor(customer?: any) {
        if (customer) {
            this.id = customer.id;
            this.name = customer.name;
            this.accountNo = customer.accountNo;
        }
    }
}

export interface IDriveInfo {
    arrivalTime: any;
    departureTime: any;
    drivingTimeInMins: number;
    distanceFromPreviousPoint: number;
    waitingTimeInMins: number;
    serviceStartTime: string;
    serviceDurationInMins: number;
}

export interface IStatusReason {
    id: number;
    code: string;
    reason: string;
}

export interface ISiteVisitSkipReason {
    date: string;
    time: string;
    reason: string;
}

export interface ISiteVisit extends IBaseEntity {
    id: number;
    reference?: string;
    orderId?: string;
    orderStatus?: string;
    orderTotal?: number;
    deliveryWindows?: DeliveryWindow[];
    deliveryPointSequence?: number;
    siteVisitSequence?: number;
    scheduledDate?: string;
    scheduledTime?: string;
    tentativeDate?: string;
    siteVisitStatus?: string;
    siteVisitType: string;
    statusReason?: IStatusReason;
    customReasons?: ISiteVisitSkipReason[];
    route?: RouteLite;
    deliveryPoint: any;
    customer: CustomerLite;
    packageCount: number;
    dirty: boolean;
    packed: boolean;
    picked: boolean;
    pickInProgress: boolean;
    stopTypeColor?: string;
    latitude?: number;
    longitude?: number;
    doLatitude?: number;
    doLongitude?: number;
    podAvailable?: number;
    deliveryOrderId?: number;
    routeSaleOrderId?: number;
    serviceLatitude?: number;
    serviceLongitude?: number;
    serviceDate?: string;
    serviceTime?: any;
    driver?: IEmployee;
    driveInfo?: IDriveInfo;
    optimized?: boolean;
    etasCalculated?: boolean;
    optimisationFailureCode?: string;
    optimisationFailureReason?: string;
    surveyPdfAvailable?: boolean;
    summaryPdfAvailable?: boolean;
    notes?: INote[];
    syncFailed: boolean;
    priority: string;
    priorityColor?: string;
    batchInfo: string[];
    deliveryNote: Note;
    driverEntryTime?: string;
    driverExitTime?: string;
    weight?: number;
    volume?: number;
    selected?: boolean;
    deliveryAddress?: any;
    orderCreatedDate?: MposDate;
    orderCreatedTime?: DiTime;
    slaColor?: string;
    invoiceNo?: string;
    dispatchTime?: any;
    saleOrderStatus?: any;
    saleOrderType?: any;
    isCollection?: boolean;
    accessCode?: string;

    getDeliveryWindowsText(): string;
    getSkippedReason(): ISiteVisitSkipReason[];
    select(): void;
    unselect(): void;
}

export class SiteVisit extends BaseEntity implements ISiteVisit {
    id: number;
    reference?: string;
    orderId?: string;
    orderStatus?: string;
    orderTotal?: number;
    deliveryWindows?: DeliveryWindow[];
    deliveryPointSequence?: number;
    siteVisitSequence?: number;
    scheduledDate?: string;
    scheduledTime?: string;
    tentativeDate?: string;
    siteVisitStatus?: string;
    siteVisitType: string;
    route?: RouteLite;
    deliveryPoint: DeliveryPointLite;
    customer: CustomerLite;
    packageCount: number;
    dirty: boolean;
    packed: boolean;
    picked: boolean;
    pickInProgress: boolean;
    stopTypeColor?: string;
    latitude?: number;
    longitude?: number;
    doLatitude?: number;
    doLongitude?: number;
    podAvailable?: number;
    deliveryOrderId?: number;
    routeSaleOrderId?: number;
    serviceLatitude?: number;
    serviceLongitude?: number;
    serviceDate?: string;
    serviceTime?: any;
    driver?: IEmployee;
    driveInfo?: IDriveInfo;
    etasCalculated?: boolean;
    optimized?: boolean;
    optimisationFailureCode?: string;
    optimisationFailureReason?: string;
    surveyPdfAvailable?: boolean;
    summaryPdfAvailable?: boolean;
    statusReason?: IStatusReason;
    customReasons?: ISiteVisitSkipReason[];
    notes?: INote[];
    syncFailed: boolean;
    priority: string;
    priorityColor?: string;
    batchInfo: string[];
    deliveryNote: Note;
    driverEntryTime?: string;
    driverExitTime?: string;
    weight?: number;
    volume?: number;
    selected?: boolean;
    deliveryAddress?: Address;
    orderCreatedDate?: MposDate;
    orderCreatedTime?: DiTime;
    slaColor?: string;
    invoiceNo?: string;
    dispatchTime?: any;
    saleOrderStatus?: any;
    saleOrderType?: any;
    isCollection?: boolean;
    accessCode?: string;

    private _selectedSubject = new BehaviorSubject<boolean>(false);
    selected$ = this._selectedSubject.asObservable();

    constructor(siteVisit?: any) {
        super();
        if (siteVisit) {
            this.id = siteVisit.id;
            this.reference = siteVisit.reference;
            this.orderId = siteVisit.orderId;
            this.orderStatus = siteVisit.orderStatus;
            this.orderTotal = siteVisit.orderTotal;
            this.serviceDate = siteVisit.serviceDate;
            this.serviceLatitude = siteVisit.serviceLat;
            this.serviceLongitude = siteVisit.serviceLong;
            this.deliveryWindows = siteVisit.deliveryWindows;
            this.deliveryPointSequence = siteVisit.deliveryPointSequence;
            this.siteVisitSequence = siteVisit.siteVisitSequence;
            this.scheduledDate = siteVisit.scheduledDate;
            this.scheduledTime = siteVisit.scheduledTime;
            this.tentativeDate = siteVisit.tentativeDate;
            this.siteVisitStatus = siteVisit.siteVisitStatus;
            this.siteVisitType = siteVisit.siteVisitType;
            this.route = siteVisit.route;
            this.deliveryPoint = siteVisit.deliveryPoint;
            this.customer = siteVisit.customer;
            this.packageCount = siteVisit.packageCount;
            this.dirty = siteVisit.dirty || false;
            this.pickInProgress = siteVisit.pickInProgress || false;
            this.picked = siteVisit.picked || false;
            this.packed = siteVisit.packed || false;
            this.stopTypeColor = siteVisit.stopTypeColor;
            this.latitude = siteVisit.latitude;
            this.longitude = siteVisit.longitude;
            this.doLatitude = siteVisit.doLatitude;
            this.doLongitude = siteVisit.doLongitude;
            this.podAvailable = siteVisit.podAvailable;
            this.deliveryOrderId = siteVisit.deliveryOrderId;
            this.routeSaleOrderId = siteVisit.routeSaleOrderId;
            this.serviceTime = DiTime.newInstance(siteVisit.serviceTime);
            this.driver = siteVisit.driver;
            this.driveInfo = siteVisit.driveInfo;
            this.optimized = siteVisit.optimized || false;
            this.surveyPdfAvailable = siteVisit.surveyPdfAvailable;
            this.summaryPdfAvailable = siteVisit.summaryPdfAvailable;
            this.statusReason = siteVisit.statusReason;
            this.customReasons = siteVisit.siteVisitSkipInfos;
            this.notes = siteVisit.notes;
            this.syncFailed = siteVisit.syncFailed;
            this.priority = siteVisit.priority;
            this.priorityColor = siteVisit.priorityColor;
            this.batchInfo = siteVisit.batchInfo;
            this.deliveryNote = siteVisit.deliveryNote;
            this.driverEntryTime = siteVisit.driverEntryTime;
            this.driverExitTime = siteVisit.driverExitTime;
            this.weight = siteVisit.weight;
            this.volume = siteVisit.volume;
            this.optimisationFailureCode = siteVisit.optimisationFailureCode;
            this.optimisationFailureReason = siteVisit.optimisationFailureReason;
            this.etasCalculated = siteVisit.etasCalculated || false;
            this.selected = siteVisit.selected || false;
            this.deliveryAddress = siteVisit.deliveryAddress;
            this.orderCreatedDate = MposDate.newInstance(siteVisit.orderCreatedDate);
            this.orderCreatedTime = DiTime.newInstance(siteVisit.orderCreatedTime);
            this.slaColor = siteVisit.slaColor;
            this.invoiceNo = siteVisit.invoiceNo;
            this.isCollection = siteVisit.isCollection;
            if (siteVisit.dispatchTime != null) {
                this.dispatchTime = DiTime.newInstance(new Date(siteVisit.dispatchTime));
            }
            this.saleOrderStatus = siteVisit.saleOrderStatus;
            this.saleOrderType = siteVisit.saleOrderType;
            this.accessCode = siteVisit.accessCode;
        } else {
            this.dirty = false;
        }
    }

    clone(): IBaseEntity {
        return new SiteVisit(this);
    }

    getDeliveryWindowsText(): string {
        let dw = '';
        if (this.deliveryWindows && this.deliveryWindows.length) {
            this.deliveryWindows.sort((a, b) => a.priority - b.priority);
            const firstDW = this.deliveryWindows[0];
            if (firstDW.startTime) {
                dw = firstDW.startTime;
                if (firstDW.endTime) {
                    dw += ' - ' + firstDW.endTime;
                }
            }
            if (this.deliveryWindows.length > 1) {
                dw += ' (+' + (this.deliveryWindows.length - 1) + ')';
            }
        }
        return dw;
    }

    getSkippedReason(): ISiteVisitSkipReason[] {
        let reason = null;
        if (this.siteVisitStatus === 'SKIPPED') {
            if (this.customReasons) {
                reason = this.customReasons;
            } else if (this.statusReason) {
                reason = this.statusReason.reason;
            }
        }
        return reason;
    }

    select(): void {
        this.selected = true;
        this._selectedSubject.next(true);
    }

    unselect(): void {
        this.selected = false;
        this._selectedSubject.next(false);
    }
}

export interface ISiteVisitLite extends IBaseEntity {
    id: number;
    reference?: string;
    orderId?: string;
    orderStatus?: string;
    orderTotal?: number;
    deliveryWindows?: DeliveryWindow[];
    deliveryPointSequence?: number;
    siteVisitSequence?: number;
    scheduledDate?: string;
    scheduledTime?: string;
    tentativeDate?: string;
    siteVisitStatus?: string;
    siteVisitType: string;
    statusReason?: IStatusReason;
    customReasons?: ISiteVisitSkipReason[];
    route?: RouteLite;
    deliveryPoint: any;
    customer: CustomerLite;
    packageCount: number;
    dirty: boolean;
    packed: boolean;
    picked: boolean;
    pickInProgress: boolean;
    stopTypeColor?: string;
    latitude?: number;
    longitude?: number;
    doLatitude?: number;
    doLongitude?: number;
    podAvailable?: number;
    deliveryOrderId?: number;
    routeSaleOrderId?: number;
    serviceLatitude?: number;
    serviceLongitude?: number;
    serviceDate?: string;
    serviceTime?: any;
    driveInfo?: IDriveInfo;
    optimized?: boolean;
    etasCalculated?: boolean;
    optimisationFailureCode?: string;
    optimisationFailureReason?: string;
    surveyPdfAvailable?: boolean;
    summaryPdfAvailable?: boolean;
    syncFailed: boolean;
    priority: string;
    priorityColor?: string;
    batchInfo: string[];
    deliveryNote: Note;
    driverEntryTime?: string;
    driverExitTime?: string;
    weight?: number;
    volume?: number;
    selected?: boolean;
    deliveryAddress?: any;
    orderCreatedDate?: MposDate;
    orderCreatedTime?: DiTime;
    slaColor?: string;
    invoiceNo?: string;
    dispatchTime?: any;
    saleOrderStatus?: any;
    saleOrderType?: any;
    isCollection?: boolean;
    accessCode?: string;

    getDeliveryWindowsText(): string;
    getSkippedReason(): ISiteVisitSkipReason[];
    select(): void;
    unselect(): void;
}

export class SiteVisitLite extends BaseEntity implements ISiteVisitLite {
    id: number;
    reference?: string;
    orderId?: string;
    orderStatus?: string;
    orderTotal?: number;
    deliveryWindows?: DeliveryWindow[];
    deliveryPointSequence?: number;
    siteVisitSequence?: number;
    scheduledDate?: string;
    scheduledTime?: string;
    tentativeDate?: string;
    siteVisitStatus?: string;
    siteVisitType: string;
    route?: RouteLite;
    deliveryPoint: DeliveryPointLite;
    customer: CustomerLite;
    packageCount: number;
    dirty: boolean;
    packed: boolean;
    picked: boolean;
    pickInProgress: boolean;
    stopTypeColor?: string;
    latitude?: number;
    longitude?: number;
    doLatitude?: number;
    doLongitude?: number;
    podAvailable?: number;
    deliveryOrderId?: number;
    routeSaleOrderId?: number;
    serviceLatitude?: number;
    serviceLongitude?: number;
    serviceDate?: string;
    serviceTime?: any;
    driveInfo?: IDriveInfo;
    etasCalculated?: boolean;
    optimized?: boolean;
    optimisationFailureCode?: string;
    optimisationFailureReason?: string;
    surveyPdfAvailable?: boolean;
    summaryPdfAvailable?: boolean;
    statusReason?: IStatusReason;
    customReasons?: ISiteVisitSkipReason[];
    syncFailed: boolean;
    priority: string;
    priorityColor?: string;
    batchInfo: string[];
    deliveryNote: Note;
    driverEntryTime?: string;
    driverExitTime?: string;
    weight?: number;
    volume?: number;
    selected?: boolean;
    deliveryAddress?: Address;
    orderCreatedDate?: MposDate;
    orderCreatedTime?: DiTime;
    slaColor?: string;
    invoiceNo?: string;
    dispatchTime?: any;
    saleOrderStatus?: any;
    saleOrderType?: any;
    accessCode?: string;

    private _selectedSubject = new BehaviorSubject<boolean>(false);
    selected$ = this._selectedSubject.asObservable();

    constructor(siteVisit?: any) {
        super();
        if (siteVisit) {
            this.id = siteVisit.id;
            this.reference = siteVisit.reference;
            this.orderId = siteVisit.orderId;
            this.orderStatus = siteVisit.orderStatus;
            this.orderTotal = siteVisit.orderTotal;
            this.serviceDate = siteVisit.serviceDate;
            this.serviceLatitude = siteVisit.serviceLat;
            this.serviceLongitude = siteVisit.serviceLong;
            this.deliveryWindows = siteVisit.deliveryWindows;
            this.deliveryPointSequence = siteVisit.deliveryPointSequence;
            this.siteVisitSequence = siteVisit.siteVisitSequence;
            this.scheduledDate = siteVisit.scheduledDate;
            this.scheduledTime = siteVisit.scheduledTime;
            this.tentativeDate = siteVisit.tentativeDate;
            this.siteVisitStatus = siteVisit.siteVisitStatus;
            this.siteVisitType = siteVisit.siteVisitType;
            this.route = siteVisit.route;
            this.deliveryPoint = siteVisit.deliveryPoint;
            this.customer = siteVisit.customer;
            this.packageCount = siteVisit.packageCount;
            this.dirty = siteVisit.dirty || false;
            this.pickInProgress = siteVisit.pickInProgress || false;
            this.picked = siteVisit.picked || false;
            this.packed = siteVisit.packed || false;
            this.stopTypeColor = siteVisit.stopTypeColor;
            this.latitude = siteVisit.latitude;
            this.longitude = siteVisit.longitude;
            this.doLatitude = siteVisit.doLatitude;
            this.doLongitude = siteVisit.doLongitude;
            this.podAvailable = siteVisit.podAvailable;
            this.deliveryOrderId = siteVisit.deliveryOrderId;
            this.routeSaleOrderId = siteVisit.routeSaleOrderId;
            this.serviceTime = DiTime.newInstance(siteVisit.serviceTime);
            this.driveInfo = siteVisit.driveInfo;
            this.optimized = siteVisit.optimized || false;
            this.surveyPdfAvailable = siteVisit.surveyPdfAvailable;
            this.summaryPdfAvailable = siteVisit.summaryPdfAvailable;
            this.statusReason = siteVisit.statusReason;
            this.customReasons = siteVisit.siteVisitSkipInfos;
            this.syncFailed = siteVisit.syncFailed;
            this.priority = siteVisit.priority;
            this.priorityColor = siteVisit.priorityColor;
            this.batchInfo = siteVisit.batchInfo;
            this.deliveryNote = siteVisit.deliveryNote;
            this.driverEntryTime = siteVisit.driverEntryTime;
            this.driverExitTime = siteVisit.driverExitTime;
            this.weight = siteVisit.weight;
            this.volume = siteVisit.volume;
            this.optimisationFailureCode = siteVisit.optimisationFailureCode;
            this.optimisationFailureReason = siteVisit.optimisationFailureReason;
            this.etasCalculated = siteVisit.etasCalculated || false;
            this.selected = siteVisit.selected || false;
            this.deliveryAddress = siteVisit.deliveryAddress;
            this.orderCreatedDate = MposDate.newInstance(siteVisit.orderCreatedDate);
            this.orderCreatedTime = DiTime.newInstance(siteVisit.orderCreatedTime);
            this.slaColor = siteVisit.slaColor;
            this.invoiceNo = siteVisit.invoiceNo;
            if (siteVisit.dispatchTime != null) {
                this.dispatchTime = DiTime.newInstance(new Date(siteVisit.dispatchTime));
            }
            this.saleOrderStatus = siteVisit.saleOrderStatus;
            this.saleOrderType = siteVisit.saleOrderType;
            this.accessCode = siteVisit.accessCode;
        } else {
            this.dirty = false;
        }
    }

    clone(): IBaseEntity {
        return new SiteVisitLite(this);
    }

    getDeliveryWindowsText(): string {
        let dw = '';
        if (this.deliveryWindows && this.deliveryWindows.length) {
            this.deliveryWindows.sort((a, b) => a.priority - b.priority);
            const firstDW = this.deliveryWindows[0];
            if (firstDW.startTime) {
                dw = firstDW.startTime;
                if (firstDW.endTime) {
                    dw += ' - ' + firstDW.endTime;
                }
            }
            if (this.deliveryWindows.length > 1) {
                dw += ' (+' + (this.deliveryWindows.length - 1) + ')';
            }
        }
        return dw;
    }

    getSkippedReason(): ISiteVisitSkipReason[] {
        let reason = null;
        if (this.siteVisitStatus === 'SKIPPED') {
            if (this.customReasons) {
                reason = this.customReasons;
            } else if (this.statusReason) {
                reason = this.statusReason.reason;
            }
        }
        return reason;
    }

    select(): void {
        this.selected = true;
        this._selectedSubject.next(true);
    }

    unselect(): void {
        this.selected = false;
        this._selectedSubject.next(false);
    }
}
