import {IEntityAction, ActionMeta} from 'app/blocks/store/actions/entity-actions';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {IPayment} from 'app/blocks/model/payment.model';

export interface IUpdatePaymentAllocated extends IEntityAction {
    entity?: IPayment;
}

export class UpdatePaymentAllocated implements IUpdatePaymentAllocated {
    static readonly type = '[Payment] Update Payment';
    constructor(
        public resource: AbstractEntityResource<any>,
        public entity: IPayment,
        meta?: ActionMeta
    ) {}
}
